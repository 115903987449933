<template>
  <div>
    <div id="stepExp1" v-if="countExperienceData == 1" class="px-3 pt-10">
      <v-form ref="form" v-model="addPersonalData.datosValidPer">
        <p key="Exp" ref="Exp" class="text-center black--text font-weight-bold subtitle-1 mb-0">
          ¿Tienes experiencia laboral?
        </p>
        <v-radio-group v-model="addExperienceData.flag_se" class="body-1"
          :rules="[(v) => !!v || 'Selecciona una opción']" required>
          <v-radio label="Sí" value="0" color="teal" class="pa-2">
          </v-radio>
          <v-radio label="No" value="1" color="teal" class="pa-2"></v-radio>
        </v-radio-group>
        <v-expand-transition>
          <div id="stepExp2" v-if="addExperienceData.flag_se === '0'" class="px-3 pt-4">
            <p key="typeExp" ref="typeExp" class="text-center black--text font-weight-bold subtitle-1 mb-4">
              Tipo de experiencia
            </p>
            <v-checkbox v-model="addExperienceData.typeOfExperience" color="teal" label="En Call Center" value="call"
              @change="clearRadio"></v-checkbox>
            <div v-if="addExperienceData.typeOfExperience.includes('call')">
              <p class="body-2 font-weight-bold">¿Has trabajado en Konecta?</p>
              <v-radio-group v-model="addExperienceData.work_konecta" class="body-1"
                :rules="[(v) => !!v || 'Selecciona una opción']" required>
                <v-radio label="Sí" value="Si" color="teal" class="pa-2">
                </v-radio>
                <v-radio label="No" value="No" color="teal" class="pa-2"></v-radio>
              </v-radio-group>
            </div>
            <v-checkbox v-model="addExperienceData.typeOfExperience" color="teal" :rules="[() => addExperienceData.typeOfExperience.length > 0
              || 'Selecciona al menos una opción']" label="En otro rubro" value="otro"></v-checkbox>
          </div>
        </v-expand-transition>
      </v-form>
    </div>
    <div id="stepExp2" v-if="countExperienceData == 2" class="px-3 pt-10">
      <v-form ref="form" v-model="addPersonalData.datosValidPer">
        <p class="text-center black--text font-weight-bold title mb-6 mx-8">CALL CENTER</p>
        <p class="text-center black--text font-weight-bold subtitle-1 mb-0 mx-8">
          ¿En qué área te has desempeñado?
        </p>
        <p class="caption">Selecciona hasta dos opciones</p>
        <v-checkbox v-model="addExperienceData.areasOfExperiencieCall" color="teal" label="Atención al cliente"
          value="Atención al cliente"></v-checkbox>
        <v-checkbox v-model="addExperienceData.areasOfExperiencieCall" color="teal" label="Back Office"
          value="Back Office"></v-checkbox>
        <v-checkbox v-model="addExperienceData.areasOfExperiencieCall" color="teal" label="Crosselling"
          value="Crosseling"></v-checkbox>
        <v-checkbox v-model="addExperienceData.areasOfExperiencieCall" color="teal" label="Redes Sociales"
          value="Redes Sociales"></v-checkbox>
        <v-checkbox v-model="addExperienceData.areasOfExperiencieCall" color="teal" label="Ventas"
          value="Ventas"></v-checkbox>
        <v-checkbox v-model="addExperienceData.areasOfExperiencieCall" color="teal" label="Otros" :rules="[() => addExperienceData.areasOfExperiencieCall.length > 0
          || 'Selecciona al menos una opción',
        () => addExperienceData.areasOfExperiencieCall.length < 3
          || 'Elige sólo dos opciones']" value="Otros"></v-checkbox>
        <v-expand-transition>
          <div v-if="
            addExperienceData.areasOfExperiencieCall.includes('Atención al cliente')
            && addExperienceData.areasOfExperiencieCall.length < 3
          " class="px-3 pt-4">
            <v-card max-width="500" class="d-flex flex-column justify-start mx-auto mt-8 rounded-card" elevation="1"
              height="320" width="100%" style='border: 1px solid teal;'>
              <p class="text-start font-weight-bold subtitle-1 px-4 py-3">
                Atención al cliente:
              </p>
              <v-text-field maxlength="40" v-model.trim="addExperienceData.customerSupport.nameEnterprise" color="teal"
                class="px-4 grey-label" outlined label="Nombre del call" placeholder="Nombre del call" required
                :rules="[(v) => !!v || 'Ingresa el nombre del call']"></v-text-field>
              <v-select v-model="addExperienceData.customerSupport.clientCategory" :items="clientCategory"
                label="Rubro del cliente" color="teal" class="px-4 grey-label" outlined
                :rules="[(v) => !!v || 'Selecciona un rubro']" placeholder="Seleccionar" required></v-select>
              <v-row class="px-4" no-gutters>
                <v-col cols="3">
                  <v-text-field class="grey-label" v-model.trim="addExperienceData.customerSupport.yearsExperience"
                    color="teal" type="number" min="0" max="10" outlined required :rules="[(v) => !!v || 'Ingresa el dato',
                    v => v >= 0 || 'Ingresa el dato',
                    (v) => v < 25 || 'Ingresa el dato']"></v-text-field>
                </v-col>
                <v-col cols="3" class="d-flex align-self-center pl-1">
                  <p>años</p>
                </v-col>
                <v-col cols="3">
                  <v-text-field class="grey-label" v-model.trim="addExperienceData.customerSupport.monthExperience"
                    color="teal" type="number" outlined required :rules="[(v) => !!v || 'Ingresa el dato',
                    v => v >= 0 || 'Ingresa el dato',
                    (v) => v < 12 || 'Ingresa el dato']"></v-text-field>
                </v-col>
                <v-col cols="3" class="d-flex align-self-center pl-1">
                  <p>meses</p>
                </v-col>
              </v-row>
            </v-card>
          </div>
        </v-expand-transition>
        <v-expand-transition>
          <div v-if="addExperienceData.areasOfExperiencieCall.includes('Back Office')
            && addExperienceData.areasOfExperiencieCall.length < 3
          " class="px-3 pt-4">
            <v-card max-width="500" class="d-flex flex-column justify-start mx-auto mt-8 rounded-card" elevation="1"
              height="320" width="100%" style='border: 1px solid teal;'>
              <p class="text-start font-weight-bold subtitle-1 px-4 py-3">
                Back Office:
              </p>
              <v-text-field maxlength="40" v-model.trim="addExperienceData.backOffice.nameEnterprise" color="teal"
                class="px-4 grey-label" outlined label="Nombre del call" placeholder="Nombre del call" required
                :rules="[(v) => !!v || 'Ingresa el nombre del call']"></v-text-field>
              <v-select v-model="addExperienceData.backOffice.clientCategory" :items="clientCategory" color="teal"
                class="px-4 grey-label" outlined :rules="[(v) => !!v || 'Selecciona un rubro']"
                label="Rubro del cliente" placeholder="Seleccionar" required></v-select>
              <v-row class="px-4" no-gutters>
                <v-col cols="3">
                  <v-text-field v-model.trim="addExperienceData.backOffice.yearsExperience" color="teal" type="number"
                    outlined required :rules="[(v) => !!v || 'Ingresa el dato',
                    v => v >= 0 || 'Ingresa el dato',
                    (v) => v < 25 || 'Ingresa el dato']"></v-text-field>
                </v-col>
                <v-col cols="3" class="d-flex align-self-center pl-1">
                  <p>años</p>
                </v-col>
                <v-col cols="3">
                  <v-text-field v-model.trim="addExperienceData.backOffice.monthExperience" color="teal" type="number"
                    outlined required :rules="[(v) => !!v || 'Ingresa el dato',
                    v => v >= 0 || 'Ingresa el dato',
                    (v) => v < 12 || 'Ingresa el dato']"></v-text-field>
                </v-col>
                <v-col cols="3" class="d-flex align-self-center pl-1">
                  <p>meses</p>
                </v-col>
              </v-row>
            </v-card>
          </div>
        </v-expand-transition>
        <v-expand-transition>
          <div v-if="addExperienceData.areasOfExperiencieCall.includes('Redes Sociales')
            && addExperienceData.areasOfExperiencieCall.length < 3
          " class="px-3 pt-4">
            <v-card max-width="500" class="d-flex flex-column justify-start mx-auto mt-8 rounded-card" elevation="1"
              height="320" width="100%" style='border: 1px solid teal;'>
              <p class="text-start font-weight-bold subtitle-1 px-4 py-3">
                Redes Sociales:
              </p>
              <v-text-field maxlength="40" v-model.trim="addExperienceData.socialNetworks.nameEnterprise" color="teal"
                class="px-4 grey-label" outlined label="Nombre del call" placeholder="Nombre del call" required
                :rules="[(v) => !!v || 'Ingresa el nombre del call']"></v-text-field>
              <v-select v-model="addExperienceData.socialNetworks.clientCategory" :items="clientCategory" color="teal"
                class="px-4 grey-label" outlined :rules="[(v) => !!v || 'Selecciona un rubro']"
                label="Rubro del cliente" placeholder="Seleccionar" required></v-select>
              <v-row class="px-4" no-gutters>
                <v-col cols="3">
                  <v-text-field v-model.trim="addExperienceData.socialNetworks.yearsExperience" color="teal"
                    type="number" outlined required :rules="[(v) => !!v || 'Ingresa el dato',
                    v => v >= 0 || 'Ingresa el dato',
                    (v) => v < 25 || 'Ingresa el dato']"></v-text-field>
                </v-col>
                <v-col cols="3" class="d-flex align-self-center pl-1">
                  <p>años</p>
                </v-col>
                <v-col cols="3">
                  <v-text-field v-model.trim="addExperienceData.socialNetworks.monthExperience" color="teal"
                    type="number" outlined required :rules="[(v) => !!v || 'Ingresa el dato',
                    v => v >= 0 || 'Ingresa el dato',
                    (v) => v < 12 || 'Ingresa el dato']"></v-text-field>
                </v-col>
                <v-col cols="3" class="d-flex align-self-center pl-1">
                  <p>meses</p>
                </v-col>
              </v-row>
            </v-card>
          </div>
        </v-expand-transition>
        <v-expand-transition>
          <div v-if="addExperienceData.areasOfExperiencieCall.includes('Ventas')
            && addExperienceData.areasOfExperiencieCall.length < 3
          " class="px-3 pt-4">
            <v-card max-width="500" class="d-flex flex-column justify-start mx-auto mt-8 rounded-card" elevation="1"
              height="320" width="100%" style='border: 1px solid teal;'>
              <p class="text-start font-weight-bold subtitle-1 px-4 py-3">
                Ventas:
              </p>
              <v-text-field maxlength="40" v-model.trim="addExperienceData.sales.nameEnterprise" color="teal"
                class="px-4 grey-label" outlined label="Nombre del call" placeholder="Nombre del call" required
                :rules="[(v) => !!v || 'Ingresa el nombre del call']"></v-text-field>
              <v-select v-model="addExperienceData.sales.clientCategory" :items="clientCategory" color="teal"
                class="px-4 grey-label" outlined :rules="[(v) => !!v || 'Selecciona un rubro']"
                label="Rubro del cliente" placeholder="Seleccionar" required></v-select>
              <v-row class="px-4" no-gutters>
                <v-col cols="3">
                  <v-text-field v-model.trim="addExperienceData.sales.yearsExperience" color="teal" type="number"
                    outlined required :rules="[(v) => !!v || 'Ingresa el dato',
                    v => v >= 0 || 'Ingresa el dato',
                    (v) => v < 25 || 'Ingresa el dato']"></v-text-field>
                </v-col>
                <v-col cols="3" class="d-flex align-self-center pl-1">
                  <p>años</p>
                </v-col>
                <v-col cols="3">
                  <v-text-field v-model.trim="addExperienceData.sales.monthExperience" color="teal" type="number"
                    outlined required :rules="[(v) => !!v || 'Ingresa el dato',
                    v => v >= 0 || 'Ingresa el dato',
                    (v) => v < 12 || 'Ingresa el dato']"></v-text-field>
                </v-col>
                <v-col cols="3" class="d-flex align-self-center pl-1">
                  <p>meses</p>
                </v-col>
              </v-row>
            </v-card>
          </div>
        </v-expand-transition>
        <v-expand-transition>
          <div v-if="addExperienceData.areasOfExperiencieCall.includes('Crosseling')
            && addExperienceData.areasOfExperiencieCall.length < 3
          " class="px-3 pt-4">
            <v-card max-width="500" class="d-flex flex-column justify-start mx-auto mt-8 rounded-card" elevation="1"
              height="320" width="100%" style='border: 1px solid teal;'>
              <p class="text-start font-weight-bold subtitle-1 px-4 py-3">
                Crosselling:
              </p>
              <v-text-field maxlength="40" v-model.trim="addExperienceData.crosseling.nameEnterprise" color="teal"
                class="px-4 grey-label" outlined label="Nombre del call" placeholder="Nombre del call" required
                :rules="[(v) => !!v || 'Ingresa el nombre del call']"></v-text-field>
              <v-select v-model="addExperienceData.crosseling.clientCategory" :items="clientCategory" color="teal"
                class="px-4 grey-label" outlined :rules="[(v) => !!v || 'Selecciona un rubro']"
                label="Rubro del cliente" placeholder="Seleccionar" required></v-select>
              <v-row class="px-4" no-gutters>
                <v-col cols="3">
                  <v-text-field v-model.trim="addExperienceData.crosseling.yearsExperience" color="teal" type="number"
                    outlined required :rules="[(v) => !!v || 'Ingresa el dato',
                    v => v >= 0 || 'Ingresa el dato',
                    (v) => v < 25 || 'Ingresa el dato']"></v-text-field>
                </v-col>
                <v-col cols="3" class="d-flex align-self-center pl-1">
                  <p>años</p>
                </v-col>
                <v-col cols="3">
                  <v-text-field v-model.trim="addExperienceData.crosseling.monthExperience" color="teal" type="number"
                    outlined required :rules="[(v) => !!v || 'Ingresa el dato',
                    v => v >= 0 || 'Ingresa el dato',
                    (v) => v < 12 || 'Ingresa el dato']"></v-text-field>
                </v-col>
                <v-col cols="3" class="d-flex align-self-center pl-1">
                  <p>meses</p>
                </v-col>
              </v-row>
            </v-card>
          </div>
        </v-expand-transition>
        <v-expand-transition>
          <div v-if="addExperienceData.areasOfExperiencieCall.includes('Otros')
            && addExperienceData.areasOfExperiencieCall.length < 3
          " class="px-3 pt-4">
            <v-card max-width="500" class="d-flex flex-column justify-start mx-auto mt-8 rounded-card" elevation="1"
              height="320" width="100%" style='border: 1px solid teal;'>
              <p class="text-start font-weight-bold subtitle-1 px-4 py-3">
                Otros:
              </p>
              <v-text-field maxlength="40" v-model.trim="addExperienceData.others.nameEnterprise" color="teal"
                class="px-4 grey-label" outlined label="Nombre del call" placeholder="Nombre del call" required
                :rules="[(v) => !!v || 'Ingresa el nombre del call']"></v-text-field>
              <v-select v-model="addExperienceData.others.clientCategory" :items="clientCategory" color="teal"
                class="px-4 grey-label" outlined :rules="[(v) => !!v || 'Selecciona un rubro']"
                label="Rubro del cliente" placeholder="Seleccionar" required></v-select>
              <v-row class="px-4" no-gutters>
                <v-col cols="3">
                  <v-text-field v-model.trim="addExperienceData.others.yearsExperience" color="teal" type="number"
                    outlined required :rules="[(v) => !!v || 'Ingresa el dato',
                    v => v >= 0 || 'Ingresa el dato',
                    (v) => v < 25 || 'Ingresa el dato']"></v-text-field>
                </v-col>
                <v-col cols="3" class="d-flex align-self-center pl-1">
                  <p>años</p>
                </v-col>
                <v-col cols="3">
                  <v-text-field v-model.trim="addExperienceData.others.monthExperience" color="teal" type="number"
                    outlined required :rules="[(v) => !!v || 'Ingresa el dato',
                    v => v >= 0 || 'Ingresa el dato',
                    (v) => v < 12 || 'Ingresa el dato']"></v-text-field>
                </v-col>
                <v-col cols="3" class="d-flex align-self-center pl-1">
                  <p>meses</p>
                </v-col>
              </v-row>
            </v-card>
          </div>
        </v-expand-transition>
      </v-form>
    </div>
    <div id="stepExp2" v-if="countExperienceData == 3" class="px-3 pt-10">
      <p class="text-center black--text font-weight-bold title mb-6 mx-8">OTRO RUBRO</p>
      <v-form ref="form" v-model="addPersonalData.datosValidPer">
        <v-card max-width="500" class="d-flex flex-column justify-start mx-auto mt-8 rounded-card" elevation="1"
          height="350" width="100%" style='border: 1px solid teal;'>
          <v-text-field maxlength="40" v-model.trim="addExperienceData.otherRubro.nameEnterprise" color="teal"
            class="px-4 pt-4 grey-label" outlined label="Nombre de la empresa" placeholder="Nombre de la empresa"
            required :rules="[(v) => !!v || 'Ingresa el nombre de la empresa']"></v-text-field>
          <v-select v-model="addExperienceData.otherRubro.clientCategory" :items="clientCategory" color="teal"
            class="px-4 grey-label" outlined :rules="[(v) => !!v || 'Selecciona un rubro']" label="Rubro de la empresa"
            placeholder="Seleccionar" required></v-select>
          <v-select v-model="addExperienceData.otherRubro.position" :items="position" outlined class="px-4 grey-label"
            color="teal" :rules="[(v) => !!v || 'Selecciona un puesto']" label="Cargo" placeholder="Seleccionar"
            required></v-select>
          <v-row class="px-4" no-gutters>
            <v-col cols="3">
              <v-text-field v-model.trim="addExperienceData.otherRubro.yearsExperience" color="teal" type="number"
                outlined required :rules="[(v) => !!v || 'Ingresa el dato',
                v => v >= 0 || 'Ingresa el dato',
                (v) => v < 25 || 'Ingresa el dato']"></v-text-field>
            </v-col>
            <v-col cols="3" class="d-flex align-self-center pl-1">
              <p>años</p>
            </v-col>
            <v-col cols="3">
              <v-text-field v-model.trim="addExperienceData.otherRubro.monthExperience" color="teal" type="number"
                outlined required :rules="[(v) => !!v || 'Ingresa el dato',
                v => v >= 0 || 'Ingresa el dato',
                (v) => v < 12 || 'Ingresa el dato']"></v-text-field>
            </v-col>
            <v-col cols="3" class="d-flex align-self-center pl-1">
              <p>meses</p>
            </v-col>
          </v-row>
        </v-card>
      </v-form>
    </div>
    <div id="stepExp2" v-if="countExperienceData == 4" class="px-3 pt-10">
      <p key="InfoAditional" ref="InfoAditional" class="text-center black--text font-weight-bold subtitle-1">
        4. Queremos conocer tus preferencias
      </p>
      <v-img src="../../../assets/profesionales.png" aspect-ratio="1.7" contain style="border-radius: 50%"></v-img>
      <p class="grey--text text--darken-1 subtitle-1 text-center pt-4">
        Es importante conocer tus preferencias para ofrecerte propuestas
        adecuadas a lo que estás buscando.
      </p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    countExperienceData: Number,
    addExperienceData: {
      type: Object,
      required: true,
    },
    addPersonalData: {
      type: Object,
      required: true,
    },
    allDatosExp: Array,
    addExp: Function,
  },
  watch: {
    addPersonalData(newVal, oldVal) {
      console.log('addPersonalData', newVal);
      console.log('oldVal', oldVal);
    },
    allDatosExp(newVal, oldVal) {
      console.log('allDatosExp', newVal);
      console.log('oldVal', oldVal);
    },
  },
  data() {
    return {
      clientCategory: [
        'Retail (Saga, Ripley, Oechsle, etc.)',
        'Supermercado',
        'Banca y finanzas',
        'Seguros',
        'Salud',
        'Educación',
        'Mejoramiento del hogar (Sodimac, Maestro, Promart, etc.)',
        'Turismo y hotelería (agencia de viajes, etc.)',
        'Aerolíneas',
        'Servicios públicos (Luz, agua, gas, etc)',
        'Telefonía fija o móvil (Movistar, Entel, Claro, etc.)',
        'Otros',
      ],
      position: [
        'Atención al cliente',
        'Ventas',
        'Impulsador de productos',
        'Cajero',
        'Reponedor',
        'Almacén, Distribución y Reparto',
        'Soporte Técnico',
        'Atención en Salud',
        'Administrativo y Tramites',
        'Servicio de Transporte',
        'Operario',
        'Profesor',
        'Desarrollo de contenido(audiovisual, escrito, edición)',
        'Community Manager y Redes Sociales',
        'Asistente',
        'Secretaria',
        'Otros',
      ],
    };
  },
  methods: {
    clearRadio() {
      if (!this.addExperienceData.typeOfExperience.includes('call')) {
        this.addExperienceData.work_konecta = null;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.v-text-field .v-text-field--enclosed .v-text-field__details {
  margin-bottom: 0 !important;
}

.rounded-card {
  border-radius: 20px;
}

.v-card>*:first-child:not(.v-btn):not(.v-chip),
.v-card>.v-card__progress *:not(.v-btn):not(.v-chip) {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
</style>
